import React, { Component } from "react";
import PageHelmet from "../../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../../component/header/Header";
import Footer from "../../component/footer/Footer";


class WebDevlopmentDetails extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <React.Fragment>
                
                {/* Start Pagehelmet  */}
                <PageHelmet pageTitle='Web Devlopment' />
                {/* End Pagehelmet  */}

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--21"  data-black-overlay="5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">WEB DEVELOPMENT</h2>
                                    <p>We specialize in creating captivating websites and dynamic web applications that combine stunning design with seamless functionality. Our team of developers ensures your online presence is not only visually appealing but also user-friendly and responsive.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content pb--80 align-items-center">
                                            <div className="col-lg-6 col-12">
                                                <div className="thumb">
                                                    <img className="w-100" src="/assets/images/service/web-devlopment.png" alt="Service Images"/>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <p>In today's digital age, a strong online presence is paramount, and that's where web development comes into play. At Appera Solutions, we are passionate about crafting exceptional web experiences that empower businesses and individuals to succeed in the digital landscape.</p>
                                                    <h4 className="title">Our Web Development services :</h4>
                                                    <ul className="liststyle">
                                                        <li><b>Custom Website Design :</b> We believe that your website should be as unique as your brand. Our expert designers work closely with you to create visually stunning, tailored website designs that reflect your identity and captivate your audience.</li>
                                                        <li><b>Front-End Development :</b> Our front-end developers are masters of HTML, CSS, and JavaScript. They bring your design to life, ensuring that your website is not only aesthetically pleasing but also user-friendly and responsive on all devices.</li>
                                                        <li><b>Back-End Development :</b> The backbone of your website lies in its functionality and performance. Our back-end developers employ cutting-edge technologies to build robust, secure, and scalable web applications that meet your specific requirements.</li>
                                                        <li><b>E-commerce Solutions :</b> For businesses looking to sell products or services online, we specialize in creating seamless and secure e-commerce websites. Our solutions include payment gateways, inventory management, and a smooth shopping experience for your customers.</li>
                                                        <li><b>Web Application Development :</b> Beyond static websites, we excel in developing dynamic web applications that provide unique functionality and interactivity. Whether it's a customer portal, booking system, or any specialized requirement, we've got you covered.</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                        {/* Start Single Area */}
                                        {/* <div className="row sercice-details-content align-items-center">
                                            <div className="col-lg-6 col-12 order-2 order-lg-1">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <p>but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum. You need to be sure there isn't anything embarrassing</p>
                                                    <p>hidden in the middle of text. All the Lorem Ipsum generators tend toitrrepeat predefined chunks. Necessary, making this the first true generator on the Internet.</p>
                                                    <h4 className="title">Our Working Process</h4>
                                                    <ul className="liststyle">
                                                        <li>Yet this above sewed flirted opened ouch</li>
                                                        <li>Goldfinch realistic sporadic ingenuous</li>
                                                        <li>Abominable this abidin far successfully then like piquan</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12 order-1 order-lg-2">
                                                <div className="thumb position-relative">
                                                    <img className="w-100" src="/assets/images/service/service-02.png" alt="Service Images"/>
                                                    <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='ZOoVOfieAF8' onClose={() => this.setState({isOpen: false})} />
                                                    <button className="video-popup" onClick={this.openModal}><span className="play-icon"></span></button>
                                                </div>
                                            </div>
                                        </div> */}
                                        {/* End Single Area */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}
                
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />

            </React.Fragment>
        )
    }
}
export default WebDevlopmentDetails;